import React from 'react'
import { Link, PageProps, useStaticQuery, graphql } from 'gatsby'

import {
  CenteredResponsiveContainer,
  Heading,
  Layout,
  locations,
  UnderlinedTextLink,
} from '@/components/Layout'
import { SEO } from '@/components/SEO'

import { ReactComponent as JumpCrypto } from '@/images/jumpcrypto.svg'

export default function Home(props: PageProps) {
  const { site } = useStaticQuery<IndexQueryType>(IndexStaticQuery)
  const { siteUrl } = site.siteMetadata

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Jump Trading',
    '@id': 'https://www.jumptrading.com/#organization', // should never change
    mainEntityOfPage: 'https://www.jumptrading.com/#organization',
    url: 'https://www.jumptrading.com',
    sameAs: [
      'https://jumptrading.com',
      'https://en.wikipedia.org/wiki/Jump_Trading',
      'https://twitter.com/jumptrading',
      'https://www.linkedin.com/company/jump-trading',
      'https://github.com/jumptrading',
    ],
    alternateName: ['JumpTrading', 'Jump Trading LLC', 'Jump Trading Group'],
    description:
      'Jump Trading is a division of Jump Trading Group, a leading data and research-driven trading business.',
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}/logo-and-name.png`,
      height: 275,
      width: 331,
    },
    logo: {
      '@type': 'ImageObject',
      url: `${siteUrl}/logo-and-name.png`,
      height: 275,
      width: 331,
    },
    subOrganization: [
      {
        '@type': 'Organization',
        '@id': 'https://jumpcap.com/#organization',
        name: 'Jump Capital',
      },
      {
        '@type': 'Organization',
        '@id': 'https://jumpcrypto.com/#organization',
        name: 'Jump Crypto',
      },
      {
        '@type': 'Organization',
        '@id': 'https://www.jumpliquidity.com/#organization',
        name: 'Jump Liquidity',
      },
    ],
    telephone: '+13122058200',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'US',
      addressLocality: 'Chicago',
      addressRegion: 'IL',
      name: 'Jump Crypto',
      postalCode: '60654',
      streetAddress: '600 West Chicago Ave. #600',
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '8:00',
        closes: '18:00',
      },
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        email: 'info@jumptrading.com',
        contactType: 'general inquiries',
      },
      {
        '@type': 'ContactPoint',
        email: 'mediainquiries@jumptrading.com',
        contactType: 'media inquiries',
      },
    ],
    email: 'info@jumptrading.com',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: '41.8973863',
      longitude: '-87.6436308',
    },
  }

  return (
    <Layout>
      <SEO
        description="Jump Trading is a division of Jump Trading Group, a leading data and research-driven trading business"
        pathname={props.location.pathname}
      >
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </SEO>

      <section className="min-h-96 mb-6 xs:mt-4 lg:-mt-8 xl:-mt-12">
        <div className="max-w-7xl px-2 md:px-4 py-0 mx-auto md:space-x-10 lg:px-8">
          <div className="relative w-100">
            <div
              className=" bg-map-with-locations bg-no-repeat bg-scroll
            min-h-[100px] min-w-[100px]
            resize max-h-[500px] max-w-[900px]
            aspect-auto
            h-[55vw] ml-auto"
            />
          </div>
        </div>
        <div
          className="sm:max-w-7xl px-0 sm:px-2 md:px-4 sm:mx-auto md:space-x-10 lg:px-8
          sm:-mt-16 md:-mt-40 lg:-mt-56 xl:-mt-70"
        >
          <div className="border-l-8 border-l-jump-red border border-y-0 border-r-0 mb-10 pt-3">
            <div
              className="text-5xl md:text-6xl pl-2 font-bold md:pl-7 max-w-2xl"
              style={{ lineHeight: 1.08 }}
            >
              <h1 className="z-20 relative">Jump Trading is committed to world class research.</h1>
            </div>
          </div>
        </div>
        <CenteredResponsiveContainer>
          <div className="flex flex-col mx-2 md:w-5/6 lg:w-2/3 xl:w-1/2 sm:ml-10 md:ml-20  lg:ml-32">
            <div className="text-dark-gray mb-4 max-w-xl text-2xl z-20 relative">
              We empower exceptional talents in Mathematics, Physics, and Computer Science to seek
              scientific boundaries, push through them, and apply cutting-edge research to global
              financial markets.
            </div>
            <div className="mt-4">
              <UnderlinedTextLink className="block border-dark-gray uppercase tracking-wider font-medium">
                <Link to="/careers/">See open positions</Link>
              </UnderlinedTextLink>
            </div>

            <div className="flex flex-col align-top gap-4 mb-10 mt-10 max-w-xl">
              <Heading>Locations</Heading>
              <ul
                role="list"
                className="grid grid-cols-2 w-full gap-x-6 gap-y-3
                sm:grid-cols-3 justify-center lg:justify-items-start"
              >
                {locations.map((location) => (
                  <li key={location} className="col-span-1">
                    <h2 className="text-2xl whitespace-nowrap">{location}</h2>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CenteredResponsiveContainer>
      </section>

      <section className="min-h-96 py-20 bg-light-gray ">
        <div
          className="max-w-7xl  mx-auto  lg:px-8 w-full
        grid grid-cols-1 auto-rows-min  lg:grid-rows-2 lg:grid-cols-[1fr_345px] justify-start items-center gap-y-6"
        >
          <div className=" px-0 sm:px-2 md:px-4">
            <div className="border-l-8 border-l-jump-red border border-y-0 border-r-0 pt-3">
              <div
                className="text-4xl md:text-5xl pl-2 font-bold md:pl-7 max-w-[760px]"
                style={{ lineHeight: 1.08 }}
              >
                <h1 className="">
                  Jump Crypto is building toward the next frontier in crypto infrastructure.
                </h1>
              </div>
            </div>
          </div>
          <div
            className="flex flex-col
           row-span-1 col-start-1 w-full "
          >
            <div className="mx-4 sm:ml-10 md:ml-20  lg:ml-32">
              <div className="text-dark-gray mb-4 max-w-xl text-2xl ">
                Jump has been partnering, innovating and trading in the crypto world for years. The
                aim of Jump Crypto is to help shape the future of the industry.
              </div>
              <div className="mt-6">
                <UnderlinedTextLink className="block border-dark-gray uppercase tracking-wider font-medium">
                  <a target="_blank" rel="noopener noreferrer" href="https://jumpcrypto.com">
                    Visit Jump Crypto
                  </a>
                </UnderlinedTextLink>
              </div>
            </div>
          </div>
          <div
            className="
        col-span-1 row-start-3 row-end-4 lg:row-start-1 lg:row-span-2 lg:col-start-2 justify-self-center self-center"
          >
            <div className="w-[345px] h-[345px] rounded-full bg-black pt-[130px] pl-[30px]">
              <JumpCrypto className="h-full w-full" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

type IndexQueryType = {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  }
}
const IndexStaticQuery = graphql`
  query Index {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
